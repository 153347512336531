import service from "./axios";


// 创建渠道类型
export const qudaoCreateQudaotype = (params) => service.post(`/jdzjy/createQudaotype`, params);
// 获取渠道类型列表
export const qudaoTypeList = (params) => service.get(`/jdzjy/typeList`, params);
// 修改渠道类型
export const qudaoEditType = (params) => service.post(`/jdzjy/editType`, params);
// 删除渠道类型
export const qudaoDelType = (params) => service.post(`/jdzjy/delType`, params);


// 创建渠道
export const createQudao = (params) => service.post(`/jdzjy/createQudao`, params);
// 获取渠道
export const qudaoList = (params) => service.post(`/jdzjy/searchQudao`, params);
// 修改渠道
export const editQudao = (params) => service.post(`/jdzjy/editQudao`, params);
// 删除渠道
export const delQudao = (params) => service.post(`/jdzjy/delQudao`, params);

// 创建票种
export const createTicket = (params) => service.post(`/jdzjy/createTicket`, params);
export const createOrderOut = (params) => service.post(`/order/createOrderOut`, params);
// 获取票种
export const ticketList = (params) => service.get(`/jdzjy/ticketList`, params);
// 修改票种
export const editTicket = (params) => service.post(`/jdzjy/editTicket`, params);
// 删除票种
export const delTicket = (params) => service.post(`/jdzjy/delTicket`, params);
// 获取记忆票种区域
export const getTicketArea  = (params) => service.get(`/manager/hallDetail?id=${params}`);


// 获取票种
export const ticketByid = (params) => service.post(`/jdzjy/ticketByid`, params);
// 获取渠道id
export const qudaoByid = (params) => service.post(`/jdzjy/qudaoByid`, params);
export const qudaoByDid = (params) => service.post(`/jdzjy/qudaoByDid`, params);


// 数据统计
export const getReportByWay = (params) => service.post('/manager/getReportByWay', params)
export const getReportByQuDao = (params) => service.post('/manager/getReportByQuDao', params)


// 财务录入
export const createRecord = (params) => service.post(`/jdzjy/createRecord`, params);
export const recordlist = (params) => service.post(`/jdzjy/recordlist`, params);

export const editRecord = (params) => service.post(`/jdzjy/editRecord`, params);
export const recordeditlist = (params) => service.post(`/jdzjy/recordeditlist`, params);
